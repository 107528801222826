var app;
(function (app) {
    var career;
    (function (career) {
        'use strict';
        var CareerController = /** @class */ (function () {
            /* @ngInject */
            CareerController.$inject = ["$scope", "$element", "$document", "$timeout", "$q", "dataservice", "$state", "$location", "$rootScope", "rxData"];
            function CareerController($scope, $element, $document, $timeout, $q, dataservice, $state, $location, $rootScope, rxData) {
                var _this = this;
                this.$scope = $scope;
                this.$element = $element;
                this.$document = $document;
                this.$timeout = $timeout;
                this.$q = $q;
                this.dataservice = dataservice;
                this.$state = $state;
                this.$location = $location;
                this.$rootScope = $rootScope;
                this.rxData = rxData;
                this.JobsObj = {
                    countries: []
                };
                this.preloader1 = true;
                this.$onInit = function () {
                    rxData.rootScopeOnready().then(function (data) {
                        _this.pageOBJ = data;
                        console.log(' this.pageOBJ timeline', _this.pageOBJ);
                        $timeout(function () {
                            $scope.$apply();
                        });
                    }, function (err) {
                        _this.pageOBJ = false;
                        console.log('error on home', err);
                    });
                };
                var listener2 = $rootScope.$watch('JobsData', function (newVal, oldval) {
                    if (newVal) {
                        _this.jobOrgData = newVal;
                        _this.$timeout(function () {
                            _this.JobsData = _this.manageJobsData(newVal);
                            _this.preloader1 = false;
                            // open more-info in new window
                            _this.$timeout(function () {
                                _this.gotoLink();
                            }, 100);
                        }, 300);
                        listener2(); // cancel
                    }
                });
            }
            ;
            CareerController.prototype.gotoLink = function () {
                var elems = this.$element.find('[data-href]');
                console.log('elems found?', elems);
                if (elems) {
                    $.each(elems, function (index, value) {
                        var link = $(value).attr('data-href');
                        if (link) {
                            $(value).bind('click', function () {
                                window.open(link, '_blank');
                                console.log('clicked', link);
                            });
                        }
                    });
                }
            };
            CareerController.prototype.selectedCountry = function (str) {
                var _this = this;
                this.sortBy = str;
                console.log('selected country ', this.sortBy);
                if (this.jobOrgData) {
                    this.$timeout(function () {
                        _this.JobsData = _this.manageJobsData(_this.jobOrgData, _this.sortBy);
                        _this.preloader1 = false;
                        // open more-info in new window
                        _this.$timeout(function () {
                            _this.gotoLink();
                        }, 100);
                    }, 300);
                    // this.$scope.$apply()
                }
                return str;
            };
            CareerController.prototype.manageJobsData = function (obj, sortby) {
                if (sortby === void 0) { sortby = false; }
                var countries = [];
                _.forEach(obj, function (item, i) {
                    // console.log("item.country",item.country)
                    if (item.country) {
                        countries.push(item.country);
                    }
                });
                countries = _.uniq(countries);
                var sortedObj = {};
                _.forEach(obj, function (item, i) {
                    if (item.function) {
                        var matched = _.filter(obj, function (o) {
                            return o.function === item.function;
                        });
                        sortedObj[item.function] = matched;
                    }
                });
                //console.log('sortedObj test',sortedObj)
                // modify obj to array
                var functionArray = [];
                if (_.isObject(sortedObj)) {
                    for (var key in sortedObj) {
                        if (sortedObj.hasOwnProperty(key)) {
                            /// hide elems by sort by
                            if (sortby != false) {
                                var matched = _.filter(sortedObj[key], function (o) {
                                    return o.country === sortby;
                                });
                                if (matched.length > 0) {
                                    sortedObj[key] = matched;
                                }
                                else {
                                    continue;
                                }
                                functionArray.push({ 'items': sortedObj[key], 'function': key });
                            }
                            else {
                                functionArray.push({ 'items': sortedObj[key], 'function': key });
                            }
                        }
                    }
                }
                this.JobsObj.countries = countries;
                console.log('functionArray', functionArray);
                return functionArray;
            };
            return CareerController;
        }());
        career.CareerController = CareerController;
        var CareerComponent = /** @class */ (function () {
            function CareerComponent() {
                this.restrict = 'E';
                this.controllerAs = 'vm';
                this.templateUrl = 'dist/js/app.career.html';
                this.controller = CareerController;
            }
            return CareerComponent;
        }());
        angular
            .module('app.career', []);
        angular
            .module('app.career').component('career', new CareerComponent());
    })(career = app.career || (app.career = {}));
})(app || (app = {}));
